.form--effects {
  %input--group {
    position: relative;
    background: $white;
    border: 1px solid $gray;
    border-radius: 4px;
    width: 100%;
    height: 3em;
    display: inline-block;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    z-index: 1;
    label {
      position: absolute;
      top: 0.75em;
      left: 1em;
      color: $black;
      opacity: 0.5;
      z-index: 5;
      transition: all 0.2s ease-in-out;
      padding-left: 2px;
      padding-right: 2px;
      font-weight: 400;
      background: transparent;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: 0;
      padding-left: 1em;
      padding-right: 1em;
      background: transparent;
      border: 1px solid $gray;
      border-radius: 4px;
      z-index: 10;
      font-size: 1em;
      &:active {
        border-color: $gray;
      }
    }
  }

  .input--group {
    @extend %input--group;
    &__active {
      @extend %input--group;
      label {
        transform: translate(0, -1.5em) scale(1.1, 1.1);
        opacity: 0.8;
        z-index: 15;
        background: $white;
        padding-bottom: 0;
        font-size: .8em;
        line-height: 1.25em;
      }
    }
  }

  %textarea--group {
    position: relative;
    background: $white;
    border: 1px solid $gray;
    border-radius: 4px;
    width: 100%;
    min-height: 216px;
    display: inline-block;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    z-index: 0;
    label {
      position: absolute;
      top: .75em;
      left: 1em;
      color: $black;
      opacity: .5;
      z-index: 5;
      transition: all 0.2s ease-in-out;
      padding-left: 2px;
      padding-right: 2px;
      font-weight: 400;
    }
    textarea {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 0.5em;
      padding-left: 1em;
      padding-right: 1em;
      background: transparent;
      border: 1px solid $gray;
      border-radius: 4px;
      z-index: 10;
      &:active {
        border-color: $gray;
      }
    }
  }

  .textarea--group {
    @extend %textarea--group;
    &__active {
      @extend %textarea--group;
      label {
        transform: translate(0, -1.5em) scale(1.1, 1.1);
        opacity: 0.8;
        z-index: 15;
        background: $white;
        padding-bottom: 0;
        font-size: .8em;
        line-height: 1.25em;
      }
    }
  }
}


.form--label__left {
  %group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 1em;
    label {
      flex: 1;
      text-align: right;
      padding-right: 2em;
    }
    input[type="text"],
    input[type="email"] {
      flex: 4;
      font-size: 0.75em;
      line-height: 1.5rem;
      border-radius: 4px;
      border: 1px solid $gray;
    }
    select {
      display: block;
      background: $white;
      flex: 4;
      font-size: 0.75em;
      line-height: 1.5rem;
      height: 2rem;
      &::ms-expand {
        border: 0;
        background-color: transparent;
      }
    }
  }
  .input--group {
    @extend %group;
  }
  .select--group {
    @extend %group;
  }
}


.button--group {
  @include respond-to(small) {
    position: relative;
    text-align: center;
    button {
      margin: 0 auto;
      &:focus {
        outline: 0;
      }
      &:active {
        background: palette(blue, darker);
        border-color: palette(blue, darker);
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      content: '';
      width: 25%;
      height: 1px;
      background: $gray;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      content: '';
      width: 25%;
      height: 1px;
      background: $gray;
    }
  }
  &__wide {
    @extend .button--group;
    width: 50%;
    margin: 0 auto;
  }
}

input::placeholder,
textarea::placeholder {
  color: $black;
  font-size: .85em;
  opacity: .6;
}

.errors {
  color: goldenrod;
  background: #ffc;
  border: 1px solid goldenrod;
  border-radius: 4px;
  padding: 1em;
  margin: 1em 0;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0;
    }
  }
}

.success {
  color: #374d38;
  background: #CDDECE;
  border: 1px solid #374d38;
  border-radius: 4px;
  padding: 1em;
  margin: 1em 0;
}
