html { box-sizing: border-box; }
* { box-sizing: inherit; }

body {
  @include typographic();
  font: 400 16px/23px $sans;
  text-align: center;
  color: $black;
  background: $blue__dark;
  text-align: left;
}

main {
  background: $white;
}

// .inverted {
//   padding: 4em 2em;
//   margin-top: 4em;
//   margin-bottom: 4em;
//   background: $black;
//   color: $white;
//   text-align: center;
//   strong,
//   em {
//     color: palette(blue);
//   }
// }

.callout {
  font-size: 1.3em;
  font-weight: 300;
  p {
    width: 66%;
    margin: auto;
  }
  strong,
  em {
    font-weight: 400;
  }
}

.offset--header {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offset--h2 {
  margin: 0;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
}

.offset--p {
  margin: 0;
}

.columns__three {
  display: flex;
  justify-content: space-between;
  margin-top: 4em;
  margin-bottom: 4em;
  text-align: center;
  h5 {
    margin-top: 0;
    color: palette(blue);
  }
  div {
    padding-left: 3em;
    padding-right: 3em;
  }
  & div:first-child {
    border-right: 1px solid $black;
  }
  & div:last-child {
    border-left: 1px solid $black;
  }
}

.section--heading {
  font: normal 28px/46px $serif;
  color: palette(blue);
}

.border--left {
  border-top: 7px solid palette(blue);
  margin-top: 2em;
  @include respond-to(small) {
    margin-top: 0;
    margin-left: 1em;
    padding-left: 1em;
    border-left: 7px solid palette(blue);
    border-top: 0;
  }

}
