.hero--home {
  display: none;
  @include respond-to(small) {
    display: block;
    background:  url('../img/hero--home__bg.png') center center no-repeat, linear-gradient(palette(darkBlue), palette(blue));
    text-align: center;
    padding-top: 4em;
  }
}

.hero--heading {
  margin: 0;
  padding-top: 7.4rem;
  font: 400 1.125em/2.5 $serif;
  color: palette(blue, lighter);
  text-transform: uppercase;
}

.hero--subheading {
  margin: 0 auto;
  padding-bottom: 7.4rem;
  font: 400 2.5em/1.15 $serif;
  color: $white;
  max-width: 20em;
  strong {
    font-weight: bold;
    font-style: italic;
  }
}

.button--hero {
  @extend %button--link;
  padding-top: 4em;
  padding-bottom: 3em;
  position: relative;
  &:after {
    content: '\f107';
    position: absolute;
    bottom: 0em;
    left: 50%;
    right: 50%;
    font-family: FontAwesome;
    color: $white;
  }
}
