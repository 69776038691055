a {
  color: palette(blue, light);
  text-decoration: none;
  &:hover {
    color: palette(blue);
  }
}

em {
  font-weight: 700;
  font-style: italic;
}

strong {
  font-weight: 700;
}

%headline {
  font: normal 28px $serif;
  margin-bottom: 0;
}

%subheadline {
  font: normal 18px $serif;
  color: palette(blue, lighter);
  margin-top: 0;
}

section > .headline,
.column > .headline {
  @extend %headline;
}

section > .subheadline,
.column > .subheadline {
  @extend %subheadline;
}

.column > .headline {
  margin-top: 0;
}

.text--centered {
  text-align: center;
}
