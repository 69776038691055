.narrow {

}

.marketing {
  color: palette(blue);
  .button--primary {
    display: inline-block;
    margin-top: 2em;
    font: normal 12px $serif;
    text-transform: none;
    padding-left: 1em;
    padding-right: 1em;
  }
}

%extra-padding {
  padding: 6em 1em;
}

.bordered__top {
  @extend %extra-padding;
  border-top: 1px solid $gray;
  h3.section--heading {
    margin-top: 0;
  }
  @include respond-to(medium) {
    padding: 0;
    h3.section--heading {
      margin-top: 1.22491em;
    }
  }
}

.bordered {
  @extend %extra-padding;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  h3.section--heading {
    margin-top: 0;
  }
  @include respond-to(medium) {
    padding: 0;
    h3.section--heading {
      margin-top: 1.22491em;
    }
  }
}

%image {
  @include respond-to(medium) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
  }
  img {
    flex: 1;
    max-width: 100%;
    margin-bottom: -5px;
    @include respond-to(small) {
      margin-bottom: 0;
      width: 507px;
    }
  }
  div {
    flex: 2;
    height: 100%;
    padding-left: 1em;
    padding-right: 1em;
    ul {
      list-style: none;
      @include respond-to(small) {
        list-style: disc;
      }
    }
  }
}

.image__left {
  @extend %image;
  div {
    @include respond-to(medium) {
      padding-left: 10%;
    }
    @include respond-to(large) {
      padding-left: 20%;
      padding-right: 1em;
    }
  }
}

.image__right {
  @extend %image;
  flex-direction: row-reverse;
  div {
    @include respond-to(medium) {
      padding-left: 10%;
    }
    @include respond-to(large) {
      padding-left: 1em;
      padding-right: 20%;
    }
  }
}

.inverted {
  color: $white;
  background: $blue__mid;
  padding-top: 4em;
  padding-bottom: 4em;
  h2 {
    color: $white;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 1em;
  }
  .button--primary {
    // padding-left: 1em;
    // padding-right: 1em;
  }
}

.logos {

  li {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0;
    display: block;
    @include respond-to(small) {
      display: inline-block;
      padding-left: 2em;
      padding-right: 2em;
    }
    // &:first-child {
    //   padding-right: 0.5em;
    //   @include respond-to(small) {
    //     padding-right: 0;
    //   }
    // }
    // &:last-child {
    //   padding-left: 0.5em;
    //   @include respond-to(small) {
    //     padding-left: 0;
    //   }
    // }
  }
  img {
    opacity: 0.5;
    margin: 0 auto;
  }
}
