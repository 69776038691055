%inline {
  list-style: none;
  li {
    display: inline-block;
  }
}

.list__arrow {
  @extend %inline;;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  li {
    padding: 0;
    padding-left: 2em;
    position: relative;
    &:before {
      content: '\f01d';
      font-family: 'FontAwesome';
      position: absolute;
      left: 0;
      bottom: .25em;
    }
  }
}

//list--inline list--checked list--gradient_l2d

.list--inline {
  @extend %inline;
  li {
    padding-left: 2em;
    padding-right: 2em;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.list--gradient__l2d {
  li {
    font-weight: bold;
    font-size: 0.875em;
    i {
      color: $gold;
    }
    &:first-child {
      color: palette(blue, lighter);
    }

    &:nth-child(2) {
      color: palette(blue, light);
    }

    &:nth-child(3) {
      color: palette(blue);
    }

    &:last-child {
      color: palette(blue, dark);
    }
  }
}

.list--no-style {
  list-style: none;
  margin: 0;
  padding: 0;
}
