%button {
  color: $white;
  text-transform: uppercase;
  font-family: $serif;
  font-size: 0.75em;
  border-radius: 10em;
  padding: 0.75em 2em;
  border: 0;
  text-decoration: none;
  transition: all .2s ease-in-out;
  border: 2px solid palette(blue);
  display: inline-block;
  &:hover {
    border-color: palette(blue, lighter);
  }
}

.button--primary {
  @extend %button;
  background: palette(blue);
  &:hover {
    border-color: palette(blue, lighter);
    background: palette(blue, lighter);
    color: $white;
  }
}

%button--link {
  display: inline-block;
  color: $white;
  font-family: $serif;
  font-size: 0.875em;
  line-height: 1.6875;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: $white;
    text-shadow: 0 0 10px rgba(255, 255, 255, 1);
  }
}

.button--outline {
  @extend %button;
  color: $white;
  &:hover {
    color: $white;
    background: palette(blue, lighter);
  }
}
