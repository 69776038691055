.address {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 7px solid palette(blue);
  @include respond-to(small) {
    margin-top: 0;
    margin-left: 1em;
    padding-top: 0;
    padding-left: 1em;
    border-top: 0;
    border-left: 7px solid palette(blue);
  }
  strong {
    display: block;
    color: palette(blue);
  }
}
