.columns {
  @include respond-to(small) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }
  &.centered {
    align-items: center;
  }
}

.column {
  @include respond-to(small) {
    flex: 1;
  }
  &__wide {
    @extend .column;
    @include respond-to(small) {
      flex: 0 1 auto;
    }
  }
}
