// .container {
//   margin-left: auto;
//   margin-right: auto;
//   padding-top: 4em;
//   padding-bottom: 4em;
//   max-width: 95%;
//   text-align: left;
// }
//
// .container--narrow {
//   @extend .container;
//   @include respond-to(medium) {
//     max-width: 48em;
//   }
// }

.container {
  padding-top: $padding-vert/2;
  padding-bottom: $padding-vert/2;
  padding-left: $padding-horiz;
  padding-right: $padding-horiz;
  position: relative;
  @include respond-to(small) {
    padding-top: $padding-vert;
    padding-bottom: $padding-vert;
    padding-left: 0;
    padding-right: 0;
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to(medium) {
    max-width: 60em;
  }
  @include respond-to(large) {
    max-width: 70em;
  }
  &__no-padding {
    @extend .container;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.container--narrow {
  padding-top: $padding-vert;
  padding-bottom: $padding-vert;
  padding-left: $padding-horiz/2;
  padding-right: $padding-horiz/2;
  @include respond-to(small) {
    padding-left: 0;
    padding-right: 0;
    max-width: 54em;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to(medium) {
    max-width: 54em;
  }

  h3 {
    margin-top: 0;
    padding-top: 0;
  }
  &__less-padding {
    @extend .container--narrow;
    padding-top: $padding-vert / 2;
    padding-bottom: $padding-vert / 2;
  }
  &__no-padding {
    @extend .container--narrow;
    padding-top: 0;
    padding-bottom: 0;
  }
  &__no-padding-top {
    @extend .container--narrow;
    padding-top: 0;
  }
  &__no-padding-bottom {
    @extend .container--narrow;
    padding-bottom: 0;
  }
}
