.calculator {
  padding-left: $padding-horiz/2;
  padding-right: $padding-horiz/2;
  @include respond-to(small) {
    padding-left: 0;
    padding-right: 0;
    max-width: 54em;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to(medium) {
    max-width: 54em;
  }
}

ul.loan--info {
  padding-top: 1em;
  padding-bottom: 1em;
  li {
    display: flex;
    align-content: space-between;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    strong {
      flex: 1 2 auto;
    }
    span {
      flex: 0 1 auto;
      text-align: right;
    }
  }
}
