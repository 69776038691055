footer {
  padding: 1em;
  color: $white;
  text-align: center;
  @include respond-to(small) {
    padding-left: 6em;
    padding-right: 6em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
  p {
    margin: 0;
    font-size: .75em;
    line-height: 1.25em;
    @include respond-to(small) {
      line-height: 4em;
    }
  }
  a {
    color: palette(blue, lightest);
    &:hover {
      color: $white;
    }
  }
  & .list--inline {
    margin-bottom: 1em;
    @include respond-to(small) {
      margin-bottom: 0;
    }
    li {
      padding-left: 0.5em;
      padding-right: 0.5em;
      @include respond-to (small) {
        padding-left: 1em;
        padding-right: 1em;
      }
      a {
        font-size: .75em;
        line-height: 1em;
        @include respond-to(small) {
          line-height: 4em;
        }
      }
    }
  }
}
