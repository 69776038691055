.profile {
  @include respond-to(small) {
    display: flex;
    margin-top: 4em;
    margin-bottom: 4em;
  }
}

.profile--image {
  text-align: center;
  img {
    margin: 0 auto;
    max-width: 140px;
    width: 100%;
  }
  @include respond-to(small) {
    flex: 1;
  }
}

.profile--content,
.profile--content--diane {
  @include respond-to(small) {
    flex: 2;
  }
  hgroup {
    text-align: center;
    // border-bottom: 1px solid $gray;
    margin-bottom: 1em;
    margin-top: 1em;
    @include respond-to(small) {
      text-align: left;
      margin-top: 0;
      padding-top: 2em;
      padding-bottom: 1em;
    }
  }
  .profile--name {
    margin: 0;
    font: normal 24px $serif;
  }
  .profile--title {
    margin: 0;
    font: normal 18px $serif;
    color: palette(blue, light);
    text-transform: uppercase;
  }
  strong {
    font-weight: normal;
    color: palette(blue, light);
    font-family: $serif;
  }
}

.profile--content--diane {
  flex: 0;
  hgroup {
    text-align: center;
  }
}

.spacer {
  padding: 1em;
}

.center {
  text-align: center;
}

.bio__container {
  @include respond-to(small) {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
  &--section {
    text-align: center;
    flex: 0 1 50%;
    padding: 1.3em;
    h5 {
      font-weight: normal;
      font-size: 1.5em;
      border-bottom: 1px solid #bebebe;
      margin-bottom: 1em;
    }
    @include respond-to(small) {
      text-align: left;
      h5 {
        font-size: 1.5em;
        border: none;
      }
      &:first-child {
        padding: 0 2em 0 0;
      }
      &:nth-child(2) {
        border-left: 1px solid #bebebe;
        padding: 0 0 0 2em;
      }
    }
  }
}
