nav[role="navigation"] {
  background: $blue__dark;
  text-align: center;
}

.nav--primary {
  padding: 2em 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;

  @include respond-to(small) {
    max-width: 50em;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: baseline;
  }
  @include respond-to(medium) {
    max-width: 64em;

  }
  li {
    display: none;
    position: relative;
    @include respond-to(small) {
      display: block;
      padding-left: .5em;
      padding-right: .5em;
    }
    .logo {
      vertical-align: middle;
      padding: 1em 0;
      width: 200px;
      @include respond-to(small) {
        width: 100px;
      }
      @include respond-to(medium) {
        width: 125px;
      }
    }
    a{
      font-size: 0.8125em;
      font-weight: 400;
      font-family: $serif;
      text-transform: uppercase;
      color: $white;
      transition: all .2s ease-in-out;
      display: inline-block;
      line-height: 2.3em;
      &:hover {
        color: $white;
        text-shadow: 0 0 10px rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
    &:first-child {
      display: block;
      @include respond-to(small) {
        text-align: left;
      }
    }
    &:last-child {
      padding-left: 0.5em;
      padding-right: 0.5em;
      @include respond-to(small) {
        a {
          color: $white;
          text-transform: uppercase;
          font-family: $serif;
          font-size: 0.75em;
          border-radius: 10em;
          padding: 0.75em 2em;
          border: 0;
          text-decoration: none;
          transition: all .2s ease-in-out;
          border: 2px solid palette(blue);
          display: inline-block;
          background: palette(blue);
          margin-left: 0;
          flex: 1 0 100%;
          align-content: flex-end;
          &:hover {
            border-color: palette(blue, lighter);
            background: palette(blue, lighter);
            color: $white;
          }
        }
      }
      @include respond-to(medium) {
        margin-left: 20%;
      }
      &:hover {
        text-shadow: 0 0 0 rgba(255, 255, 255, 0);
      }
    }
    .sub-menu {
      @include respond-to(small) {
        display: none;
        position: absolute;
        top: 26px;
        left: 0;
        padding: 0;
        margin: 0;
      }
      li {
        padding: 0;
        margin: 0;
        width: 200%;
        a {
          border: none;
          border-radius: 0;
        }
      }
    }
    &:hover {
      .sub-menu {
        display: block;
      }
    }
  }
}

.menu--toggle {
  text-transform: uppercase;
  color: palette(blue, lightest);
  line-height: 2em;
  &:hover {
    color: $white;
  }
  @include respond-to(small) {
    display: none;
  }
}

.nav--secondary {
  background: palette(darkBlue);
  border-bottom: 1px solid palette(darkBlue, dark);
  position: relative; // for the search to work properly on mobile
  &__inner {
    margin: 0 auto;
    @include respond-to(small) {
      max-width: 64em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  nav {
    padding-right: 1em;
  }

  ul {
    // width: 64em;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
    display: none;
    @include respond-to(small) {
      display: block;
    }
  }
  li {
    display: inline;
  }
  a {
    padding-left: 1em;
    padding-right: 1em;
    font: bold .75em/2.375em $sans;
    color: palette(blue);
    &:hover {
      color: palette(darkBlue);
    }
    @include respond-to(small) {
      color: palette(blue, light);
      &:hover {
        color: palette(blue, lightest);
      }
    }
  }
}

.nav--curtain {
  background: palette(blue, darker);
  border: none;
  max-height: 0;
  text-align: center;
  overflow: hidden;
  transition: max-height 2s .2s cubic-bezier(0.15, 0.68, 0.32, 1);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:first-child {
        display: none;
      }
      a {
        color: palette(blue, lightest);
        display: block;
        line-height: 2.5em;
      }
    }
  }
  &.active {
    max-height: 1200px;
    transition: max-height 2s .2s cubic-bezier(1, 0.32, 0.68, 0.15);
  }
}
