header {
  background: linear-gradient(palette(darkBlue), palette(blue));
}

.home > header {
  background: transparent;
  h1 {
    margin-top: 0;
  }
}
